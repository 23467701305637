import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Replace with your actual API endpoint
const API_URL = 'https://deploybackend-production-bcb9.up.railway.app/api/get-product';

export const fetchProducts = createAsyncThunk(
    'product/fetchProducts',
    async (_, thunkAPI) => {
        try {
            const response = await fetch(API_URL);
            const data = await response.json();
            return data.data; // Action payload in case of success
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        loading: false,
        error: null,
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;

